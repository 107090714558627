/* Body styles */
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  background-color: #f5f5f5;
  color: #1f1f1f;
}

/* Container styles */
.survey-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-left: 5px solid #ff8c42;
  position: relative;
}

@media only screen and (min-width: 768px) {
  .survey-container {
    max-width: 800px;
  }
}

/* Container styles */
.survey-container-cen {
  max-width: 800px;
  margin: 20% auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-left: 5px solid #ff8c42;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

@media only screen and (min-width: 768px) {
  .survey-container-cen {
    max-width: 800px;
  }
}

.survey-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
}

.title-container {
  padding: 20px;
  margin-bottom: 20px;
}

.alert {
  max-width: 800px;
  margin: 20% auto;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border-left: 5px solid #ff8c42;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 3px;
  background-color: #ff8c42;
}

.alert p {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #721c24;
}

/* New container styles */
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 10px 30px;
}

/* Logo container styles */
.logo-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.logo {
  width: 200px;
  height: auto;
}

@media screen and (max-width: 600px) {
  /* Logo container styles on mobile */
  .logo-container {
    order: -1;
    margin: 0;
  }
}

/* Login Container */

.login-container {
  display: flex;
  align-items: center;
}

.login-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  margin-right: 10px;
}

.login-container {
  position: absolute;
  top: 0;
  right: 0;
}

@media (max-width: 768px) {
  .login-container {
    position: relative;
    margin-bottom: 20px;
  }
}

.login-button {
  margin-left: 20px;
}

.login-button:hover {
  background-color: #0062cc;
}

@media (max-width: 768px) {
  .login-container {
    position: relative;
    margin-bottom: 20px;
  }
}

.navbar {
  background-color: #f5f5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info p {
  margin-right: 10px;
}

/* Heading styles */
h1 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
  color: #1f1f1f;
}

/* Form styles */
form {
  margin-top: 30px;
}

/* Question container styles */
.question-container {
  margin-bottom: 30px;
}

.question-container label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1f1f1f;
}

/*Question Title Styles*/

.question-title {
  display: flex;
  align-items: center;
}

.question-title h2 {
  margin-right: 10px;
}

/* Question label styles */
.question {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #1f1f1f;
}

/* Answer select styles */
.answer {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #1f1f1f;
  font-size: 16px;
  font-weight: 400;
  appearance: none;
  margin-bottom: 20px;
}

/* Submit button styles */
button[type="submit"] {
  display: block;
  margin-top: 30px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #ff8c42;
  color: #f5f5f5;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button[type="submit"]:hover {
  background-color: #ff5e14;
}

/* Description styles */
.description {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  border-left: 5px solid #ff8c42;
}
